<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items-public :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItemsPublic from './components/horizontal-nav-menu-items/HorizontalNavMenuItemsPublic.vue'

export default {
  components: {
    HorizontalNavMenuItemsPublic,
  },
  data() {
    return {
      navMenuItems,
    }
  },
  mounted() {
    window.console.log('memo', navMenuItems)
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
