<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks />
    </div> -->

    <!-- Right Col if ログインしていない場合はログインボタンを設置 -->
    <!-- Right Col if ログインしている場合 -->
    <b-navbar-nav
      class="nav align-items-center ml-auto navbarstyle"
    >
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <b-nav-item
        v-if="isLogin"
        to="student-lesson-calendar"
      >
        <feather-icon
          icon="CalendarIcon"
          size="21"
        />
      </b-nav-item>
      <favorite-dropdown v-if="isLogin" />
      <notification-dropdown v-if="isLogin" /> -->
      <user-dropdown v-if="isLogin && flag === 1" />
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
// import FavoriteDropdown from '@/@core/layouts/components/app-navbar/components/FavoriteDropdown.vue'
// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import SearchBar from './components/SearchBar.vue'
// import CartDropdown from './components/CartDropdown.vue'

// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    // BNavItem,
    // Bookmarks,
    // Locale,
    // DarkToggler,
    // SearchBar,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    // FavoriteDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      flag: '',
    }
  },
  computed: {
    ...mapGetters('userBasic', ['userType', 'userNickName', 'mail', 'coin', 'isLogin']),
  },
  mounted() {
    // window.console.log('memo', this.userType)
    switch (this.userType) {
      case 'student':
        this.flag = 1
        break
      case 'company':
        this.flag = 2
        break
      default:
        this.flag = 1
        break
    }
  },
}
</script>

<style scoped>
.navbarstyle {
  /* padding: 1% 0 0% 0; */
  height: 40px;
}
</style>
