<template>
  <div class="">
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <b-avatar
          size="40"
          variant="light-primary"
        >
          <feather-icon
            icon="SettingsIcon"
            size="22"
          />
        </b-avatar>
      </template>
      <small class="ml-1">アカウント</small>
      <div class="dropdown_old">
        <!-- <b-dropdown-item
          :to="{ name: 'faq'}"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="HelpCircleIcon"
            class="mr-50"
          />
          <span>よくある質問</span>
        </b-dropdown-item> -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="showTerm"
        >
          <feather-icon
            size="16"
            icon="BookIcon"
            class="mr-50"
          />
          <span>受講申込規約</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="XCircleIcon"
            class="mr-50"
          />
          <span>ログアウト</span>
        </b-dropdown-item>
      </div>
    </b-nav-item-dropdown>
    <b-modal
      v-model="isShowModal"
      size="lg"
      ok-only
      ok-title="閉じる"
      ok-variant="flat-secondary"
    >
      <TermStudent />
    </b-modal>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar, BDropdownDivider,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import TermStudent from '@/components/basic/term/components/TermStudent.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    TermStudent,
    BDropdownDivider,
    // BBadge,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      flag: 1,
      isShowModal: false,
    }
  },
  computed: {
    ...mapGetters('userBasic', ['userDic', 'userType', 'userNickName', 'mail', 'coin', 'avatar', 'teacherID']),
  },
  watch: {
    userType: {
      handler() {
        if (this.userType === 'teacher') this.flag = 2
      },
      immediate: true,
    },
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      store.dispatch('userBasic/updateIsLogin', false)
      store.dispatch('userBasic/resetAll')

      // Redirect to login page
      this.$router.push({ name: 'login' })
      // this.$router.push({ name: 'auth-login' })
    },
    move(val) {
      this.$router.push({ name: val })
    },
    showTerm() {
      this.isShowModal = true
    },
  },
}
</script>

<style scoped>
.outline_ {
  outline: 1px solid rgb(134, 134, 134);
  /* padding: 1%; */
  border-radius: 2px;
  padding: 0 5%;
  /* font-size: 0.8rem; */
}
.mailSpace {
  margin: 3% 7% 5% 7%;
}
.coinSpace {
  display: flex;
  justify-content: space-between;
}
.btnSpace {
  width: 90%;
  padding: 4% 3%;
  margin: 3% auto;
}
.dropdown_{
  /* 画面の高さが700px以上の場合は高さを700pxに固定する */
  /* max-height: 100px;
  overflow-y: auto; */
  height: 700px;
  overflow: auto; /* 高さを固定しないとスクロールしない */
}
@media screen and (max-height:700px){
  /* 画面の高さが700px以下の場合 */
  .dropdown_{
    height: 400px;
    overflow: auto;
  }
}
</style>
